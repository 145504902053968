/* eslint-disable no-prototype-builtins */
/**
 * This file is the runtime component of CCM that supports dynamic components based on a mapping
 * of properties and mapping of class names.
 *
 * This runtime implementation could be swapped for a build-time generated component
 * that could result in faster components instead of looping over props for each render
 */
import { createElement, forwardRef, Ref } from 'react';

import { CCMDefinition, ComponentCreator, CompOrTag } from './types';
import { CCM_DEFINITION } from './get-ccm-definition';
import { useBaseClassFor } from './base-elements';

const mapProps = (
  { base, prop, mod }: CCMDefinition,
  { className = '', style = {}, ...props },
  ref: Ref<unknown>,
  baseClassName: string
) =>
  Object.keys(props).reduce(
    (transformedProps: { [key: string]: any }, propName) => {
      if (prop.hasOwnProperty(propName)) {
        transformedProps.style[prop[propName]] = props[propName];
      } else if (mod.hasOwnProperty(propName)) {
        // Only add the className if the props have set it to something truthy: `props[propName]`
        if (props[propName]) {
          transformedProps.className += ' ' + mod[propName];
        }
      } else {
        transformedProps[propName] = props[propName];
      }
      return transformedProps;
    },
    {
      ref,
      style: { ...style },
      className: `${base} ${baseClassName} ${className || ''} `.trim()
    }
  );

const createComponentForTag = (ccm: CCMDefinition, compOrTag: CompOrTag) => {
  const Component = forwardRef((props, ref) => {
    const baseClass = useBaseClassFor(compOrTag);
    return createElement(compOrTag, mapProps(ccm, props, ref, baseClass));
  });
  Component.displayName = `${ccm.name}.${
    typeof compOrTag === 'string'
      ? compOrTag
      : compOrTag.displayName || compOrTag.name
  }`;
  return Component;
};

export const createComponentCreator = <T>(
  ccm: CCMDefinition
): ComponentCreator<T> => {
  const cache = new Map();
  const getComponent = (compOrTag: CompOrTag) => {
    if (compOrTag === 'as') {
      return getComponent;
    }
    if (((compOrTag as unknown) as symbol) === CCM_DEFINITION) {
      return ccm;
    }
    return (
      cache.get(compOrTag) ||
      cache.set(compOrTag, createComponentForTag(ccm, compOrTag)).get(compOrTag)
    );
  };
  return (new Proxy(cache, {
    get: (_, tag) => getComponent(tag as any)
  }) as any) as ComponentCreator<T>;
};

export const createComponentCreators = <T>(components: CCMDefinition[]) =>
  components.reduce<{ [name: string]: ComponentCreator<T> }>(
    (createComponents, component) => {
      createComponents[component.name] = createComponentCreator(component);
      return createComponents;
    },
    {}
  );
