import {
  createElement,
  createContext,
  useMemo,
  useCallback,
  FC,
  useContext
} from 'react';
import { CompOrTag, ComponentCreator } from './types';
import { getCCMDefinition } from './get-ccm-definition';

export type BaseElement = ComponentCreator<object>;
// Must be a set of CCM components, without any props or modifiers
export type BaseElements = Partial<
  Record<keyof JSX.IntrinsicElements, BaseElement>
>;

const BaseElementContext = createContext<(comp: CompOrTag) => string>(() => '');

export interface BaseElementsProviderProps {
  /**
   * Pass a set of CCM components for each DOM element you want to set base styles for
   */
  baseElements: BaseElements;
  /**
   * Optionally set a CCM component that acts as a Universal selector element '*'
   *
   * Note: The specificity of this is a bit different; a '*' has no priority but a class does
   */
  universalBaseElement?: BaseElement;
}

/**
 * Configure with base ccm elements to act as a scoped "reset" for your components
 */
export const BaseElementsProvider: FC<BaseElementsProviderProps> = ({
  baseElements,
  universalBaseElement,
  children
}) => {
  const universalBaseClass = useMemo(
    () =>
      universalBaseElement ? getCCMDefinition(universalBaseElement).base : '',
    [universalBaseElement]
  );

  const baseElementClasses = useMemo(
    () =>
      Object.entries(baseElements).reduce(
        (acc, [baseElementName, baseElement]) => {
          const baseElementClass = baseElement
            ? getCCMDefinition(baseElement).base
            : '';
          acc[
            baseElementName
          ] = `${baseElementClass} ${universalBaseClass}`.trim();
          return acc;
        },
        {} as any
      ),
    [baseElements, universalBaseClass]
  );

  const getBaseClassFor = useCallback(
    (comp: CompOrTag): string => {
      if (typeof comp !== 'string') {
        return '';
      }
      return baseElementClasses[comp] || universalBaseClass;
    },
    [universalBaseClass, baseElementClasses]
  );

  return (
    <BaseElementContext.Provider value={getBaseClassFor} children={children} />
  );
};

export const useBaseClassFor = (comp: CompOrTag) =>
  useContext(BaseElementContext)(comp);
